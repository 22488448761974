div .slider .slider-frame {
  display: flex !important;
  justify-content: center !important;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}

div .slider .slider-control-bottomcenter {
  position: relative !important;
  display: flex !important;
  justify-content: center !important;
  bottom: 10px !important;
}

@media (min-width: 880px) {
  div .slider .slider-control-centerright {
    right: -25px !important;
  }

  div .slider .slider-control-centerleft {
    left: -20px !important;
  }
}

@media (max-width: 880px) {
  div .slider .slider-control-centerright {
    right: -15px !important;
  }

  div .slider .slider-control-centerleft {
    left: -16px !important;
  }
}

@media (max-width: 450px) {
  div .slider .slider-control-centerright {
    right: -10px !important;
  }

  div .slider .slider-control-centerleft {
    left: -10px !important;
  }
}

div .slider .slider-control-bottomcenter ul li {
  padding: 0.2rem;
}

div .slider .slider-control-bottomcenter ul li :focus {
  outline: 0;
}

div .slider .slider-control-bottomcenter ul li button span {
  width: 1rem !important;
  height: 1rem !important;
  margin: 0 0.5rem !important;
  background: #d9dee6 !important;
}
